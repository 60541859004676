<template>
  <div
    class="wall_content"
    style="background-image: url('img/qixi/cp_wall_bg.png');padding-bottom: 30px;"
  >
    <div
      class="back"
      style="background-image: url('img/qixi/back.png')"
      @click="goBack"
    ></div>
    <div class="cp_list">
      <div
        class="cp_item"
        v-for="(item, index) in list"
        :key="index"
        style="background-image: url('img/qixi/cp_bg.png')"
      >
        <div class="cp_index">{{ index + 1 }}</div>
        <div class="mid" style="display: flex; align-items: center">
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img class="cp_head" :src="item.a_head_portrait" alt="" />
            <div class="cp_nickname">{{ item.a_nickname }}</div>
          </div>
          <img class="heart" src="img/qixi/heart.png" alt="" />
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img class="cp_head" :src="item.b_head_portrait" alt="" />
            <div class="cp_nickname">{{ item.b_nickname }}</div>
          </div>
        </div>
        <div class="cp_vulue">甜蜜值：{{ item.inr_value }}</div>
      </div>
    </div>
    <div
      class="bottom_text"
      style="background-image: url('img/qixi/bottom_text.png')"
    ></div>
  </div>
</template>
<script>
import { cpRank } from "@/api/ranking";
import "@/assets/css/qixi.css";
export default {
  data() {
    return {
      list: [],
      searchData: {
        page: 1,
        rows: 20,
      },
    };
  },
  created() {
    this.getRank();
  },
  mounted() {
    window.addEventListener("scroll", this.Scrollbottom); //页面加载时监听滚动
  },
  destroyed() {
    window.removeEventListener("scroll", this.Scrollbottom); //页面离开后销毁监听事件
  },
  methods: {
    getRank() {
      cpRank(this.searchData).then((res) => {
        console.log(res);
        this.list = this.list.concat(res.data);
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    Scrollbottom() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.searchData.page++;
        this.getRank();
        // console.log(this.pageNo);
        // this.fetchData()
      }
    },
  },
};
</script>
